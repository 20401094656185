<template>
  <div class="container">
    <div class="card">
      <div class="card-header no-print">
        <div class="d-flex justify-content-end">
          <b-button variant="primary" size="sm" @click="print">PRINT</b-button>
        </div>
      </div>
      <div class="card-body">
        <!-- <section>
          <span>Loan Details</span>
          <p>Loan Code:{{ form.loan_id.reference_code }}</p>
          <p>Start Date:{{ form.loan_id.created_at }}</p>
        </section> -->
        <section>
          <span>Customer Details</span>
          <p>Full Name:{{ form.loan_id.lend_by.full_name }}</p>
          <p>Account ID:{{ form.loan_id.lend_by.account_id }}</p>
          <p>NIC:{{ form.loan_id.lend_by.nic }}</p>
          <p>Contact No:{{ form.loan_id.lend_by.mobile }}</p>
        </section>
        <!-- <section>
          <span>Employee Details</span>
          <p>Full Name:{{ form.handled_by.full_name }}</p>
          <p>Account ID:{{ form.handled_by.account_id }}</p>
          <p>NIC:{{ form.handled_by.nic }}</p>
          <p>Contact No:{{ form.handled_by.mobile }}</p>
        </section> -->
        <hr />
        <section>
          <p>Date: {{ form.created_at.slice(0, 10) }}</p>
          <p>Amount: {{ form.amount }}</p>
          <p>Remark: {{ form.note }}</p>
        </section>
        <hr />
        <section class="my-5">
          <div class="align-middle">
            <span>Thank you</span> <br />
            <span>Fast Lanka Micro Credit</span> <br />
            <span>Contact Number</span> <br />
            <span>0772679733/0717009733</span> <br />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import ApiService from "@/services/api.service";
export default {
  data() {
    return {
      form: {
        loan_id: {},
        handled_by: {},
        amount: null,
        note: null,
        created_at: null
      }
    };
  },
  created() {
    ApiService.get("payments/" + this.$route.params.id).then(({ data }) => {
      this.form = data.payment;
    });
  },
  methods: {
    print() {
      window.print();
    }
  }
};
</script>
<style lang="scss" scoped>
.card,
.card-body {
  margin: 5px;
  width: 58mm;
  height: 125mm;
}
.print-only {
  display: none;
}
span {
  font-size: 12px;
}
p {
  margin-bottom: 0.5rem;
}

@page {
  background: white;
  width: 58mm;
  height: 125mm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}

@media print {
  .no-print {
    display: none;
  }

  .print-only {
    display: block;
  }
  .card,
  .card-body {
    margin: 0;
    box-shadow: 0;
  }
}
</style>
